.circle-file-input {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #E5BA73;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .placeholder-text {
    color: #999;
    font-size: 14px;
    font-weight: bold;
  }

@media screen and (max-width:768px){
  .circle-file-input{
  width: 100px;
  height: 100px;
  }
}